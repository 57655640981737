import React from "react";
import Card from 'react-bootstrap/Card';
import {BsGithub, BsLinkedin} from 'react-icons/bs';
import {SiGmail} from 'react-icons/si';
const Footer = () => {
return (
    <div>
         <footer className="footer--pin">
    <ul style={{
        listStyleType: "none",
        color: "black",
    }}
    >
        <li><a href="https://www.facebook.com/jazzhounds1">Facebook</a></li>
        <li>All icons by <a target="_blank" href="https://icons8.com">Icons8</a></li>
        <li>&copy; Melanie McCord 2022</li>
    </ul>
  </footer>
    </div>
);
};
export default Footer;
