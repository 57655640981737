import logo from './logo.svg';
import './App.css';
import Home from "./pages/index";
import Bios from "./pages/bios";
import Performances from "./pages/performances";
import About from "./pages/about";
import MyNavbar from "./components/navbar";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import MapSection from './components/map/Map' // import the map here
function App() {
  return (
    <><Router>
      <MyNavbar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/bios' element={<Bios />}></Route>
        <Route path='/performances' element={<Performances />}></Route>
      </Routes>
    </Router><Footer></Footer></>
  );
}

export default App;
