import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MapSection from '../components/map/Map';
const booksAndbooks = {
    address: '265 Aragon Ave, Coral Gables, FL 33134',
    lat: 25.75078566838283, 
    lng: -80.25994494426068
  };
const elksClubLocation = {
    address: '6304 SW 78 Street, Miami, FL',
    lat: 25.704683790256084, 
    lng: -80.36091775946792
};
const Performances = () => {
    return (
        <div className='content-container'>
                <h1>Performances</h1>
    <Container>
        <Row>
            <Col>
            <h2>Elks Club</h2>
            <p>See us every Sunday and Tuesday night at the Elks Club.</p>
            <address>
                6304 SW 78th St <br></br>
                South Miami, FL 33143-4928 <br></br>
            </address>
            <MapSection location={elksClubLocation} zoomLevel={17} /> {/* include it here */}
            </Col>
            <Col><h2>Books and Books</h2>
            <p>We also sometimes have performances at Books and Books.</p>
            <address>
            265 Aragon Ave <br></br>
            Coral Gables, FL 33134
            </address>
            <MapSection location={booksAndbooks} zoomLevel={17} /> {/* include it here */}
            </Col>
        </Row>
    </Container>
        </div>
    )
};

export default Performances;
