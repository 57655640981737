import React from 'react';

const Bios = () => {
    return (
        <div className='content-container'>
            <h1>Bios</h1>
        </div>
    );
};

export default Bios;
