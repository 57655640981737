import React from 'react';
const About = () => {
    return (
        <div className='content-container'>
            <h1>About Jazz Hounds</h1>
            <p>We are a Miami-based band that plays swing, jazz, blues, standards, bossa nova, and samba.</p>
        </div>
    );
};

export default About;
