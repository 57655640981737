import React from 'react';
import jazzhounds from "./media/jazzhounds.jpg";
const Home = () => {
    return (
        <div className='content-container'>
            <h1>Jazz Hounds</h1>
            <img src={jazzhounds} alt="band" width="1000v" marginLeft="50" height="780h"/>
        </div>
    );
};

export default Home;
